/** @format */

.navigationBar {
  background-color: $base-black;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &::-webkit-scrollbar {
    display: none;
  }
  .profile {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.115rem;
    color: $base-white-t50;
    margin-top: 8px;
    cursor: pointer;
    text-align: center;
  }
}

.wrapper_link {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px 16px 16px;
  row-gap: 8px;
  @include media-lg {
    flex-direction: row;
    padding: 16px 16px 16px;
    width: 100vw;
    overflow: auto;
    justify-content: space-between;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .link_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 96px;
    width: 96px;
    border-radius: 0.8rem;
    @include transition(all, 0.2s, ease);

    &-icon {
      svg {
        fill: $neutrals-gray-600;
      }
    }

    &-text {
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.115rem;
      color: $neutrals-gray-600;
      margin-top: 8px;
    }
    &:hover,
    &.active {
      background-color: $c_nav_bg_hover;
      .link_icon-text {
        color: $base-white;
      }
      .link_icon-icon {
        color: $base-white;
        svg {
          fill: $base-white;
        }
      }
    }
    @include media-lg {
      height: 42px;
      width: 44px;
      min-width: 44px;
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
      &-text {
        display: none;
      }
    }
  }
}

.wrapper_link_bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px 16px;
  row-gap: 8px;
  .link_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40px;
    width: 96px;
    align-items: center;
    text-decoration: none;
    border-radius: 0.8rem;
    @include transition(all, 0.2s, ease);
    &-icon {
      svg {
        fill: $neutrals-gray-600;
      }
    }
    &-text {
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.115rem;
      color: $neutrals-gray-600;
    }
    &:hover,
    &.active {
      background-color: $c_nav_bg_hover;
      .link_icon-text {
        color: $base-white;
      }
      .link_icon-icon {
        color: $base-white;
        svg {
          fill: $base-white;
        }
      }
    }
    &.profile {
      height: 96px;
      width: 96px;
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0.8rem;
      @include transition(all, 0.2s, ease);
      &-icon {
        svg {
          fill: $neutrals-gray-600;
        }
      }
      .link_icon-text {
        margin-top: 8px;
      }
      &:hover,
      &.active {
        background-color: $c_nav_bg_hover;
      }
    }
  }
}

.subnavigation {
  display: flex;
  flex-direction: column;
  background-color: $neutrals-gray-900;
  color: $base-white;
  padding: 8rem 16.5rem 3rem 5.6rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  // @include media-xl {
  @media screen and (max-width: 1400px) {
    padding: 8rem 5.6rem 3rem;
  }
  @include media-lg {
    padding: 4rem 4rem 6rem;
    max-height: calc(100% - 135px);
  }
  @include media-md {
    padding: 4rem 1.6rem 6rem;
  }
}

.subnav_header-wrapper {
  display: block;
  .subnav_header-title {
    color: $base-white;
  }
  .subnav_header-sub {
    color: $base-white-t60;
    margin-top: 8px;
    line-height: 1.5;
  }
}

.logo-extranet-text {
  font: 500 10px/10px ContinentalStagSansW-M;
  letter-spacing: 0.115em;
  color: $base-white;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  position: sticky;
  top: 80px;
  z-index: 1;
  background: $base-black;
  text-transform: uppercase;
}
