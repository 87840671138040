/** @format */

.selectedCount {
  position: absolute;
  height: 32px;
  min-width: 32px;
  width: max-content;
  color: #e38702;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.05);
  top: 12px;
  left: 10px;
}

.mdl-demo {
  font-size: 16px;
  position: relative;
  z-index: 100;

  .dropdown {
    display: block !important;
    color: $base-black;

    .tag-item {
      .search {
        border: 0;
      }

      .tag {
        background-color: transparent !important;
        border: 0 !important;
        padding: 0;

        .tag-remove {
          font-size: 90%;
          margin-left: 5px;
        }
      }
    }

    .dropdown-trigger {
      width: 100%;
      border-radius: 8px;
      padding: 13px 65px 13px 13px !important;
      border-color: $base-black-t30 !important;

      &:hover {
        border-color: $primary1-yellow-800 !important;
      }

      &:focus {
        border-color: $primary1-yellow-700 !important;
      }

      &.arrow {
        cursor: text !important;
        &.bottom {
          &:hover {
            border-color: #8f5600 !important;
          }
          &::after {
            content: '' !important;
            background: url('../../assets/icons/arrowDown.svg') no-repeat;
            width: 10px;
            height: 6px;
          }
        }

        &.top {
          border-color: $primary1-yellow-700 !important;

          &::after {
            content: '' !important;
            background: url('../../assets/icons/arrowUp.svg') no-repeat;
            width: 10px;
            height: 6px;
          }
        }
      }

      &::after {
        position: absolute;
        right: 20px;
        top: 24px;
      }

      .tag-item {
        .placeholder {
          color: rgba(0, 0, 0, 0.42);
        }
        &:last-child:not(:first-child) {
          display: none;
        }
        &:not(:nth-last-child(-n + 2)) {
          margin-right: 4px;
          display: inline;
          span {
            display: inline;
          }
          &:after {
            display: inline;
            color: white;
            content: ', ';
          }
        }
      }
    }

    .dropdown-content {
      width: 100%;
      box-shadow: 0 5px 24px rgba(0, 0, 0, 0.1) !important;
      border-radius: 8px;

      .search {
        padding: 10px;
        font-size: 16px;
      }
    }
  }

  .infinite-scroll-component {
    max-height: 350px;
    padding-bottom: 3rem;

    .node {
      padding: 10px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $neutrals-gray-200;
      border-radius: 100px;
      background-clip: padding-box;
      border: 1px solid transparent;
    }
  }

  .toggle {
    font: normal normal normal 18px/1 'Material Icons';
    color: #555;
    white-space: pre;
    margin-right: 4px;

    &.collapsed::after {
      cursor: pointer;
      content: '\E5CF';
      vertical-align: middle;
    }

    &.expanded::after {
      cursor: pointer;
      content: '\E5CE';
      vertical-align: middle;
    }
  }

  .checkbox-item {
    position: relative;
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.75rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    vertical-align: middle;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
    }

    &:checked:before {
      height: 50%;
      transform: rotate(-45deg);
      border-top-style: none;
      border-right-style: none;
      border-color: #ffa500;
      border-radius: 0;
    }
  }

  &:nth-child(2) {
    .dropdown-trigger {
      padding-left: 45px !important;
    }
  }

  &.multi-select-tree-dark {
    .dropdown .dropdown-trigger {
      border-color: rgba(255, 255, 255, 0.3) !important;
      padding: 9px 65px 9px 9px !important ;
      line-height: 29px;
      font-size: 18px;
      .tag-item {
        margin-left: 0;
        /* margin-right: 0; */
        .placeholder {
          color: grey;
        }
        .tag {
          color: #fff;
          .tag-remove {
            display: none;
          }
        }
      }
      .tag-item:not(:first-child):last-child {
        .placeholder {
          display: none;
        }
      }
    }
    &:nth-child(2) .dropdown .dropdown-trigger {
      padding-left: 50px !important;
    }
    .dropdown .dropdown-content {
      background-color: #1f1f1f;
      color: #fff;
      box-shadow: 0 5px 24px rgba(0, 0, 0, 0.5) !important;
      .search {
        background-color: #1f1f1f;
        border-color: #3f3f3f;
        color: #fff;
      }
      .node.focused {
        background-color: #2f2f2f;
      }
      .node.checked {
        background-color: #2f2f2f;
        color: #d78b31;
      }
    }
    .checkbox-item:before {
      border-color: #d78b31;
    }
    .infinite-scroll-component {
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #000;
      }
    }
  }
}
.selectedCount-dark {
  background-color: #363636;
  z-index: 111;
}
