/** @format */

.pageBuilder {
  &-mainContent {
    height: calc(100vh - 278px);
    overflow: auto;
    margin-top: 160px;
    padding: 40px 194px 0 202px;
    display: flex;
    gap: 28px;
    flex-direction: column;
    scroll-behavior: smooth;
    @media screen and (max-width: 1200px) {
      padding: 0 36px;
    }
    @media screen and (max-width: 899px) {
      margin-top: 0;
      padding: 0;
      height: calc(100vh - 253px - 120px - 50px);
    }
  }
  &-mainContent-list-component {
    display: flex;
    gap: 28px;
    flex-direction: column;
  }
  &-contentFieldsWrap {
    background: #0000000d;
    padding: 24px;
    border-radius: 8px;
    > div:first-child {
      margin-bottom: 8px;
    }
    &-components {
      border-radius: 0;
      /* box-shadow: 0 -10px 10px -15px rgba(0, 0, 0, 0.5); */
      background-color: #faf4eb;
      z-index: 2;
      position: sticky;
      bottom: 0;
      margin-bottom: 0;
    }
    /* .ck .ck-rounded-corners,
    .ck .ck-editor__main > .ck-editor__editable,
    .ck .ck-editor__main > .ck-editor__editable.ck-rounded-corners {
      min-height: 300px;
    } */
    .field-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
      }
    }
  }
  &-textEditor > div > div:nth-child(2) > div:nth-child(2) {
    /* background-color: #eee; */
    width: fit-content;
  }
  &-componentList {
    margin-top: 35px;
    display: flex;
    gap: 8px;
    button {
      background-color: rgba(0, 0, 0, 0.05);
      font-size: 16px;
      padding: 0 24px;
      height: 56px;
      border-radius: 8px;
      font-weight: bold;
      border: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .fields-contact-information {
    display: flex;
    gap: 24px;
    > div:not(.component-tools) {
      flex: 1;
    }
  }
  .fields-service-number {
    display: flex;
    justify-content: space-between;
    > div:first-child {
      width: 405px;
    }
  }
  .fields-column-direction {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 78px;
  }
  .field-id-number {
    width: 200px;
  }
  .action-component {
    width: 34px;
    height: 54px;
    background-color: transparent;
    &.remove-component {
      width: 54px;
    }
    &:hover {
      opacity: 0.5;
    }
    svg {
    }
  }
}
