/** @format */

.content-tab-outer {
  @include media-xl {
    overflow: auto;
  }
}
.content-tab-wrapper {
  margin-top: 80px;
  @media screen and (max-width: 899px) {
    margin-top: 48px;
  }
}

.content-tab-wrapper-resp {
  @include media-xl {
    min-width: 1000px;
  }
}
.content-tab-wrapper-mob {
  min-width: 100%;
  height: calc(100vh - 260px);
  @media screen and (max-width: 899px) {
    min-width: initial;
    height: inherit;
  }
}

.content-card-titles {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  font: 500 16px/18px ContinentalStagSansW-M;
  letter-spacing: 0.01em;
  color: $neutrals-gray-500;
  margin-bottom: 6px;
  @media screen and (max-width: 899px) {
    font-size: 12px;
    line-height: 16px;
    padding: 0 10px;
  }

  & > [class*='content-'] {
    display: flex;
    justify-content: flex-start;
  }
}

.content-no-results {
  font-size: 1.8rem;
  padding: 24px;
}

.content-card-wrapper {
  background-color: $base-white;
  border-radius: 8px;
  // padding: 24px 24px 21px;
  padding: 23px 25px;
  display: flex;
  justify-content: space-between;
  color: $neutrals-gray-900;
  font-size: 18px;
  line-height: 29px;
  margin-bottom: 8px;

  &.unpublished {
    background-image: linear-gradient(
      135deg,
      #ffffff 37.5%,
      #dbdbdb 37.5%,
      #dbdbdb 50%,
      #ffffff 50%,
      #ffffff 87.5%,
      #dbdbdb 87.5%,
      #dbdbdb 100%
    );
    background-size: 5.66px 5.66px;
    color: $neutrals-gray-500;

    .content-actions {
      button:not(.action-menu-item):not(.active) {
        svg {
          path {
            fill: $neutrals-gray-500;
          }
        }
      }
    }

    .type-icon {
      background-color: $neutrals-gray-200;

      svg {
        fill: $neutrals-gray-500;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: $primary2-pastel-50;
    box-shadow: 0 7px 24px rgba(0, 0, 0, 0.05);
  }
  > div {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 899px) {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    padding: 15px 16px 15px 10px;
  }
}

.content-type {
  width: 18.5%;
  .type-icon {
    background-color: $primary2-pastel-300;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  .content-type-tag {
    color: $neutrals-gray-600;
    display: block;
  }
  @media screen and (max-width: 899px) {
    width: 35%;
    min-width: 110px;
    padding-right: 10px;
    .type-icon {
      height: 30px;
      width: 30px;
      svg {
        height: 13.5px;
        width: 13.5px;
      }
    }
    .content-type-tag {
      font-size: 12px;
    }
  }
}

.content-title {
  width: 28%;
  @media screen and (max-width: 899px) {
    width: 60%;
    padding-right: 10px;
    > div {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.content-created {
  width: 10%;
}
.contentBoxInnerOpen .content-created {
  width: 15%;
  min-width: 100px;
}
.content-release {
  width: 10%;
}

.content-removal {
  width: 10%;
}

.content-usergroups {
  width: 10%;
}

.content-market {
  width: 10%;
}

.content-clicks {
  width: 10%;
}

.content-actions {
  width: 3.5%;
  align-items: center;
  .content-action-menu-wrap {
    position: relative;
    justify-content: center;
    > button {
      height: 32px;
      width: 32px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 19px;
      outline: none;
      &:hover {
        background-color: $base-black-t5;
      }
      &.active {
        background-color: $primary1-yellow-700;
      }
    }
  }
  > button {
    height: 32px;
    width: 32px;
    background-color: transparent;
    outline: none;
  }
  @media screen and (max-width: 899px) {
    width: 15%;
    min-width: 50px;
    justify-content: space-between;
    > button {
      height: 24px;
      width: 24px;
    }
  }

  &.content-actions-content-page {
    position: absolute;
    top: 40px;
    right: 56px;
    z-index: 99;
    width: 40px;
    height: 40px;
    @media screen and (max-width: 768px) {
      top: 20px;
      right: 16px;
    }
    .content-action-menu-wrap > button {
      width: 40px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.05);
      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
      &.active {
        background-color: #ffa500;
      }
    }
    .action-menu {
      top: 48px;
      width: 120px;
    }
  }
}

.action-menu {
  position: absolute;
  z-index: 2;
  width: 183px;
  box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  background-color: $base-white;
  right: 0px;
  top: 36px;
  .action-menu-item {
    height: 48px;
    display: flex;
    align-items: center;
    @include p-large-medium;
    width: 100%;
    background-color: $base-white;
    span {
      width: 52px;
      display: flex;
      justify-content: center;
    }
    &:hover {
      color: $primary1-yellow-500;
      span {
        svg {
          fill: $primary1-yellow-500;

          path {
            fill: $primary1-yellow-500;
          }
        }
      }
    }
  }
}

.contentBoxInnerOpen {
  .content-type {
    width: 30%;
    padding-right: 20px;
    .content-type-tag {
      font-size: 16px;
    }
  }
  .content-title {
    width: 40%;
    padding-right: 20px;
  }
  .content-release {
    width: 15%;
    min-width: 100px;
  }
  .content-actions {
    width: 15%;
    justify-content: space-between;
    align-items: center;
    .content-action-menu-wrap {
      > button {
        &:first-of-type {
          margin-left: 20px;
          @media screen and (max-width: 899px) {
            margin-left: 0;
          }
        }
      }
    }
  }
}
.accordionBody {
  background-color: $base-white;
  color: $neutrals-gray-900;
  margin-bottom: 8px;
  margin-top: -14px;
  border-radius: 0 0 8px 8px;
  padding: 0 23px 13px 6rem;
  font-size: 18px;
  line-height: 29px;
  @media screen and (max-width: 899px) {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    padding: 22px 23px 5px 10px;
  }
  .content {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    .heading {
      color: $neutrals-gray-500;
      font-size: 17px;
      line-height: 25px;
      // width: 38%;
      padding-left: 6rem;
      // flex: 1 0 calc(35% - 38px);
      width: 35%;
      min-width: 110px;
      @media screen and (max-width: 899px) {
        padding-left: 38px;
        font-size: 12px;
        line-height: 12px;
        // max-width: 191px;
      }
    }
    .head-detail {
      // flex: 1 0 65%;
      width: 60%;
    }
    .content-blank {
      width: 15%;
    }
  }
  // @include media-sm {
  //     font-size: 1.2rem;
  //     padding: 10px 15px 15px 4rem;
  //     .content {
  //         .heading {
  //             color: $base-black-t40;
  //             width: 33%;
  //             padding-left: 1rem;
  //         }
  //     }
  // }
}

.custom-swipeable {
  &.MuiPaper-root {
    width: 90%;
    background: #faf4eb;
    border-radius: 48px 0px 0px 48px;
  }
  &.custom-swipeable-mobile {
    @media screen and (max-width: 899px) {
      width: 100%;
      height: calc(100% - 50px);
      border-radius: 48px 48px 0 0;
    }
  }
  .steps-wrapper {
    width: 100%;
    height: 100vh;
    padding: 20px 20px 0;
    .steps-inner {
      display: flex;
      align-items: center;
      margin-top: 127px;
      margin-bottom: 52px;
      @media (min-width: 900px) {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        padding-left: 56px;
        padding-right: 56px;
        z-index: 1;
        margin-top: unset;
        margin-bottom: unset;
      }
    }

    // h1 {
    //     font-size: 32px;
    //     text-align: center;
    //     @media (min-width: 768px) {
    //         font-size: 60px;
    //         text-align: left;
    //     }
    // }
    .editor-steps-heading {
      text-transform: capitalize;
      h3 {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.03em;
        margin-top: 16px;
      }
    }
    .MuiStepper-root {
      width: 100%;
      .MuiStep-root {
        .stepper-icon {
          height: 30px;
          width: 30px;
          background: $neutrals-gray-300;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          line-height: 18px;
          letter-spacing: 0.01em;
          color: $base-white;
        }
        .MuiStepLabel-labelContainer {
          color: $neutrals-gray-300;
          text-transform: uppercase;
          .MuiStepLabel-label {
            font-weight: 500;
            font-size: 10px;
            line-height: 10px;
            letter-spacing: 0.115em;
          }
          .Mui-active,
          .Mui-completed {
            color: $base-black;
          }
        }
        &.Mui-completed {
          .stepper-icon {
            background: $base-black;
          }
        }
        .active {
          .stepper-icon {
            background: $primary1-yellow-500;
            color: $base-black;
          }
        }
      }
    }
    .MuiTextField-root {
      width: 100%;
      // .MuiInputBase-root {
      //     .MuiOutlinedInput-notchedOutline {
      //         border: 1px solid $base-black-t30;
      //         border-radius: 8px;
      //     }
      // }
    }
    .text-area {
      resize: none;
      background-color: transparent;
      border: 1px solid $base-black-t30;
      border-radius: 8px;
      width: 100%;
      font: 400 17px/25px ContinentalStagSansW;
      color: $base-black;
      padding: 16.5px 14px;
      &::placeholder {
        color: $neutrals-gray-700;
      }
      &:hover,
      &:focus-visible {
        border-color: $primary1-yellow-500;
        outline: none;
      }
    }
    .link-url {
      display: flex;
      > div {
        flex: 1;
      }
    }
    .switchLabel {
      .MuiSwitch-root {
        margin-right: 20px;
      }
    }
  }
  .editor-setup-page {
    background: rgba(250, 244, 235, 0.85);
    // backdrop-filter: blur(100px);
  }
  .editor-other-page {
    background: $primary2-pastel-100;
    height: 100%;
    .steps-inner {
      margin-top: 44px;
      margin-bottom: 48px;
      .step-process {
        order: -1;
        width: 100%;
        margin-bottom: 32px;
      }
      .editor-steps-heading {
        @media screen and (max-width: 899px) {
          // width: 50%;
          width: max-content;
          h1 {
            font-size: 32px;
            line-height: 36px;
          }
          h3 {
            font-size: 17px;
            line-height: 25px;
          }
        }
      }
      .step-btn-preview {
        @media screen and (max-width: 899px) {
          // width: 50%;
          width: max-content;
        }
      }
      @media screen and (min-width: 900px) {
        top: 40px;
        margin-top: unset;
        margin-bottom: unset;
        .step-process {
          order: 0;
          margin-bottom: unset;
        }
      }
    }
  }
  .editor-setup-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $base-black;
    // height: calc(100vh - 120px);
    // height: calc(100vh - 215px);
    height: calc(100vh - 127px - 52px - 30px - 20px - 50px);
    padding-bottom: 80px;
    overflow: auto;
    // @media screen and (min-width: 900px) {
    //     margin-top: 204px;
    //     height: calc(100vh - 127px - 52px - 30px - 20px - 45px);
    // }
    @media screen and (min-width: 900px) {
      // margin-top: 170px;
      margin-top: 204px;
      height: calc(100vh - 224px);
    }
    h1 {
      text-align: center;
    }
    h3 {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.03em;
      margin-top: 120px;
      @media screen and (max-width: 899px) {
        margin-top: 53px;
      }
    }
    .content-type-outer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      @include media-md {
        margin-top: 12px;
      }

      .create-content-type {
        display: flex;
        flex-direction: column;
        text-transform: initial;
        font: 500 16px/18px ContinentalStagSansW-M;
        letter-spacing: 0.01em;
        color: $base-black;
        margin: 10px 8px;
        padding: 0;
        &:hover {
          background: none;
        }
        // @media (min-width: 629px) {
        //     margin: 10px 8px;
        // }
        @media screen and (min-width: 768px) {
          margin: 24px 12px;
        }
      }
    }
  }
}
.image-upload-box {
  background: rgba(249, 242, 229, 0.5);
  border: 1px solid $primary1-yellow-500;
  border-radius: 8px;
  max-height: 544px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .file-upload-menu {
    position: absolute;
    top: 22px;
    right: 26px;
    min-width: initial;
    padding: 10px;
  }
  .collections-icon {
    margin: 0 50px;
  }
  .drop-image-text {
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.03em;
    margin-top: 35px;
    text-align: center;
  }
}
.field-label {
  color: $base-black;
  font: 500 17px/25px ContinentalStagSansW-M;
  .field-label-name {
  }
  .field-label-helper {
    font-family: ContinentalStagSansW;
  }
}
.content-preview-wrapper {
  .preview-content {
    position: relative;
    margin-top: 30px;
  }
  h1 {
    font-weight: 400;
    font-size: 45px;
    line-height: 54px;
    position: absolute;
    top: 0;
    left: 0;
    @include media-md {
      font-size: 32px;
      line-height: 36px;
      position: relative;
      margin-bottom: 32px;
    }
  }
  hr {
    height: 0;
    border: 1px solid $base-black;
    opacity: 0.3;
  }
  .tabbed-navigation {
    .MuiTabs-root {
      background: transparent;
      width: 100%;
      margin-bottom: 15px;
      svg path {
        fill: $base-black-t10;
      }
      .MuiButtonBase-root {
        &.Mui-selected {
          background: transparent;
          svg path {
            fill: $base-black;
          }
        }
      }
    }
  }
}
.tagList {
  position: absolute;
  list-style: none;
  font-size: 1.6rem;
  padding: 0px;
  margin-top: -1.2rem;
  cursor: pointer;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  z-index: 105;
  li {
    padding: 10px 15px;
    &:hover {
      background: $primary1-yellow-500;
    }
  }
}
.news_body {
  img {
    width: 100% !important;
  }

  ul,
  ol {
    font-size: 18px;
    line-height: 25px;
    padding-left: 30px;
  }
}
.selectComponent {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px 0 30px;
  text-align: center;
  .selectComponent_txt {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
    margin: 1rem auto 2rem;
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
    .seletComponent_btn {
      .text {
        margin-left: 10px;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1%;
      }
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.05);
      padding: 10px 12px;
      border-radius: 5px;
    }
    .seletComponent_btn + .seletComponent_btn {
      margin-left: 1rem;
    }
  }
}

.addFiles {
  background: $base-black-t5;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 156px;
  position: relative;
  .selectComponent_txt {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
    margin: 1rem auto 2rem;
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
    .seletComponent_btn {
      .text {
        margin-left: 10px;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1%;
      }
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.05);
      padding: 10px 12px;
      border-radius: 5px;
    }
    .seletComponent_btn + .seletComponent_btn {
      margin-left: 1rem;
    }
  }
}

.errBorder {
  border: 1px solid $red-300;
}
.errColor {
  color: $red-300 !important;
}
.errorMessage {
  font-size: 14px;
  margin: 1.6rem 0;
}

.editorBaseWrapper {
  .search-chip {
    &.dark {
      .MuiInputAdornment-positionEnd {
        svg {
          fill: $neutrals-gray-600;
          height: 15.5px;
          width: 15.5px;
        }
      }
    }
  }
}

.dialog-editor [role='dialog'] {
  background: #faf4eb;
  border-radius: 16px;
  overflow: hidden;
}
.dialog-editor .dialog-editor-footer {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
}

.delete-modal {
  .MuiBox-root[tabindex='-1'] {
    border: none;
    background: #faf4eb;
    border-radius: 16px;
    padding: 16px 24px;
  }
}
