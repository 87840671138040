/** @format */

.filter-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 20px;
  margin-top: 59px;
  @include media-lg {
    row-gap: 16px;
  }
  > :nth-of-type(odd) {
    flex-basis: 40%;
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
    @include media-lg {
      flex-basis: 100%;
    }
  }
  > :nth-of-type(even) {
    flex-basis: 60%;
    @include media-lg {
      flex-basis: 100%;
    }
  }
}

.search-content-wrapper {
  padding-top: 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid $base-black-t25;
  .search-content-head {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.03em;
    cursor: pointer;
    @media screen and (max-width: 899px) {
      font: 500 20px/26px ContinentalStagSansW-M;
    }
  }
  .search-content {
    font-size: 18px;
    line-height: 29px;
    color: $neutrals-gray-700;
    margin-top: 16px;
    max-width: 700px;
    p {
      color: $neutrals-gray-700;
    }
    @media screen and (max-width: 899px) {
      font-size: 17px;
      line-height: 25px;
      margin-top: 8px;
    }
  }
  .search-date {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: $base-black-t40;
    width: max-content;
    margin-left: 10px;
    @media screen and (max-width: 899px) {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: normal;
    }
  }
}

.search-chip {
  &.dark {
    .MuiInputAdornment-positionEnd {
      svg {
        fill: $neutrals-gray-600;
        height: 15.5px;
        width: 15.5px;
      }
    }
  }
}
